import { navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { getSearchPath } from "../../../../common/link";
import { useDictionary } from "../../../../context/dictionary";
import { useLocale } from "../../../../context/locale";
import { Button, Form, Icon, Input } from "./box.styles";

export const ArticleSearchBox = ({onSearch}) => {
  
  const [query, setQuery] = useQueryParam("query", StringParam);
  const placeholderSearch = useDictionary("placeholderSearch");

  const [value, setValue] = useState(query);
  const locale = useLocale();
  const inputRef = useRef(null);

  

  useEffect(() => {
    setValue(query);
  },[query])

  const handleChange = (event) => {
    setValue(event.target.value);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    navigate(`${getSearchPath({locale})}?query=${value}`);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input ref={inputRef} type="text" placeholder={placeholderSearch} value={value} onChange={handleChange} />
      <Button type="submit">
        <Icon />
      </Button>
    </Form>
  );
}
