import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { Heading } from '../components/common/heading';
import { ArticleSearch } from '../components/article/search';

export const query = graphql`
  query SearchQuery($id: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageSearch(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
    versions: allDatoCmsPageSearch{
      nodes {
        locale
        model {
          apiKey
        }
      }
    }
    
  }
`;

const Search = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const versions = get(data, 'versions.nodes');

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <ArticleSearch heading={page.title} initialSize={pageContext.initialSize} paginationSize={pageContext.paginationSize} />
    </Fragment>
  );
};

export default Search;