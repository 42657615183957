import React, { useEffect, useRef, useState } from 'react';
import { useQueryParam, StringParam } from "use-query-params";
import { Header } from './search.styles';
import { useLocale } from '../../../context/locale';
import { Heading } from '../../common/heading';
import { ArticleSearchProps } from './search.types';
import { ArticleSearchBox } from './box';
import { search } from '../../../services/algolia';
import { ArticleList } from '../list';
import { parseArticles } from '../../../common/content';
import { get } from 'lodash';
import { useDictionary } from '../../../context/dictionary';
import { HeadingSize, HeadingVariant } from '../../common/heading/heading.types';

export const ArticleSearch = ({heading,initialSize,paginationSize,borderTop,borderBottom}:ArticleSearchProps) => {
  const locale = useLocale();
  const labelMore = useDictionary("labelMore");

  const responseRef = useRef();
  const pageRef = useRef();

  const [query, setQuery] = useQueryParam("query", StringParam);
  const [results, setResults] = useState([]);

  const _search = async () => {
    try{
      const response = await search(query,locale,{page:pageRef.current,hitsPerPage:pageRef.current === 0 ? initialSize : paginationSize});
      responseRef.current = response;

      setResults(response.page > 0 ? results.concat(parseArticles(response.hits,{locale})) : parseArticles(response.hits,{locale}));
    }
    catch(error){

    }
  }

  useEffect(() => {
    responseRef.current = undefined;
    pageRef.current = 0;
    _search();

  },[query])

  const onLoadMore = () => {
    pageRef.current++;
    _search();
  }
  
  return (
    <>
    <Header borderTop={borderTop} borderBottom={borderBottom}>
      {heading && <Heading size={HeadingSize.LARGE} variant={HeadingVariant.OVERLINE}>{heading}</Heading>}
      <ArticleSearchBox onSearch={undefined} />
    </Header>
    <ArticleList items={results} buttonLabel={get(pageRef, "current") < get(responseRef, "current.nbPages") - 1 ? labelMore : undefined} onButton={get(pageRef,"current") === get(responseRef, "current.page") ? onLoadMore : undefined} />
    </>
  )
};