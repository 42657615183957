import styled from "styled-components";
import { CONTAINER, getVerticalSpacingAsStyle, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";

export const Header = styled.div<{borderTop?:boolean,borderBottom?:boolean}>`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.LARGE,{bottom:false})}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL,{top:false})}
  border-top: ${({theme, borderTop}) => borderTop ? `1px solid ${theme.background.secondary}` : `none` };
  border-bottom: ${({theme, borderBottom}) => borderBottom ? `1px solid ${theme.background.secondary}` : `none` };
`;

